import {
  IRecordedActivitiesApiResponse,
  IWebinarItem,
  IYoutubeResponse,
} from '@local/src/services/recordedActivities/recordedActivities.types'
import getConfig from '@local/src/Utils/configService'
import { trrFetchBaseQuery } from '@local/src/Utils/ConfigService/Network'
import { parseYoutubeId } from '@local/src/Utils/Helpers/parseYoutubeId'
import { createApi, FetchBaseQueryError } from '@reduxjs/toolkit/query/react/'
import { ISelectOption } from '@trr/common-component-library'
import {
  flatten,
  join,
  map,
  mergeLeft,
  mergeWith,
  pluck,
  prop,
  uniq,
} from 'ramda'

export const recordedActivitiesApi = createApi({
  reducerPath: 'recordedActivitiesApi',
  baseQuery: trrFetchBaseQuery(),
  endpoints: (builder) => ({
    // Get activities with metadata
    getRecordedActivitiesWithMetaData: builder.query<
      IRecordedActivitiesApiResponse,
      {
        page?: number
        pageSize?: number
        subjectCategory?: string
      }
    >({
      queryFn: async (
        { page = 1, pageSize = 12, subjectCategory = '' },
        _queryApi,
        _extraOptions,
        fetchWithMetaData
      ) => {
        const fetchVideos = await fetchWithMetaData(
          `Content/webinars/?page=${page.toString()}&pageSize=${pageSize.toString()}&subjectCategory=${subjectCategory}`
        )
        const videos = fetchVideos.data as IRecordedActivitiesApiResponse

        if (videos.webinars.length === 0) {
          return {
            error: {
              status: 'FETCH_ERROR',
            } as FetchBaseQueryError,
          }
        }

        try {
          const fetchVideosMetaData = (await fetch(
            `https://www.googleapis.com/youtube/v3/videos?part=contentDetails,snippet&id=${join(
              ',',
              map(
                (item: IWebinarItem) => parseYoutubeId(prop('videoUrl', item)),
                videos.webinars
              )
            )}&key=${getConfig().YOUTUBE_API_KEY}`
          ).then((response) => response.json())) as IYoutubeResponse

          return {
            data: {
              ...videos,
              webinars: mergeWith(
                mergeLeft,
                videos.webinars,
                fetchVideosMetaData.items.map((video) => ({
                  duration: video?.contentDetails?.duration,
                  heading: video?.snippet?.title,
                  body: video?.snippet?.description,
                  thumbnails: video?.snippet.thumbnails,
                  id: video?.id,
                }))
              ),
            },
          }
        } catch (error) {
          return {
            data: {
              ...videos,
            },
          }
        }
      },
    }),
    // Get all categories
    getRecordedActivitiesCategories: builder.query<ISelectOption[], void>({
      query: () => `Content/webinars/?pageSize=999`,
      transformResponse: (response: { webinars: IWebinarItem[] }) =>
        map(
          (cat) => ({ label: cat, value: cat }),
          uniq(flatten(pluck('subjectCategories', response.webinars)))
        ),
    }),
  }),
})

export const {
  useGetRecordedActivitiesWithMetaDataQuery,
  useGetRecordedActivitiesCategoriesQuery,
} = recordedActivitiesApi
