import React, { useEffect } from 'react'
import {
  Card,
  CardBody,
  CardHeader,
  CardMedia,
  CardMetaDataGroup,
  Icons,
  MetaData,
} from '@trr/common-component-library'
import { isNil } from 'ramda'
import useToggle from '@local/src/Utils/Hooks/useToggle'
import { lockScrollbar } from '@local/src/Utils/Helpers/lockScrollBar'
import VideoModal from '@local/src/Components/VideoModal'
import { parseYoutubeId } from '@local/src/Utils/Helpers/parseYoutubeId'
import { parseDuration } from '@local/src/Utils/Helpers/parseDuration'
import { Thumbnails } from '@local/src/services/recordedActivities/recordedActivities.types'
import { date } from '@trr/frontend-datetime'

import s from './VideoGrid.module.scss'

export interface IVideo {
  broadcastDate?: string
  duration?: string
  heading?: string
  videoUrl?: string
  body?: string
  videoId?: string
  thumbnails: Thumbnails
}

interface IVideoGrid {
  webinarVideos: IVideo[]
}

export const VideoGrid: React.FunctionComponent<IVideoGrid> = ({
  webinarVideos,
}) => {
  const { toggle, handleToggle } = useToggle()
  const [selectedWebinar, setSelectedWebinar] = React.useState<IVideo>({
    videoUrl: '',
    heading: '',
    body: '',
    broadcastDate: '',
    duration: '',
    thumbnails: null,
  })

  const setPlay = (video: IVideo) => {
    setSelectedWebinar(video)
    handleToggle()
    lockScrollbar(true)
  }

  const handleClick = (video: IVideo) => () => {
    setPlay(video)
  }

  const handleKeyDown = (video: IVideo) => (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' || event.key === ' ') {
      setPlay(video)
    }
  }

  useEffect(() => () => lockScrollbar(false), [])

  const onClose = React.useCallback(() => {
    handleToggle()
    lockScrollbar(false)
  }, [handleToggle])

  return (
    <div className={s.VideoGrid}>
      {!isNil(webinarVideos) &&
        Object.values(webinarVideos).map((video: IVideo, key: number) => {
          const hasMetaData = video?.broadcastDate || video?.duration
          const videoThumbnail =
            video?.thumbnails &&
            Object.values(video.thumbnails)?.sort((v) => v?.height)[
              Object.values(video.thumbnails)?.length - 1
            ]
          return (
            <Card
              data-testid={'video-grid-item'}
              key={key}
              onClick={handleClick(video)}
              onKeyDown={handleKeyDown(video)}
              className={s.videoGridCardWrapper}
              tabIndex={0}
            >
              {videoThumbnail?.url && (
                <CardMedia
                  className={s.cardMediaStretch}
                  image={videoThumbnail?.url}
                  alt={video?.heading}
                />
              )}
              <CardHeader>{video?.heading}</CardHeader>
              {hasMetaData && (
                <CardMetaDataGroup>
                  {video?.broadcastDate && (
                    <MetaData
                      icon={<Icons.CalendarOutlinedIcon />}
                      text={date.format(video.broadcastDate, {
                        style: 'numeric',
                      })}
                    />
                  )}
                  {video?.duration && (
                    <MetaData
                      icon={<Icons.ClockOutlinedIcon />}
                      text={parseDuration(video.duration)}
                    />
                  )}
                </CardMetaDataGroup>
              )}
              {video?.body && <CardBody>{video.body}</CardBody>}
            </Card>
          )
        })}
      <VideoModal
        toggled={toggle}
        handleClose={onClose}
        videoId={parseYoutubeId(selectedWebinar.videoUrl)}
        heading={selectedWebinar.heading}
        body={selectedWebinar.body}
        broadcastDate={selectedWebinar.broadcastDate}
      />
    </div>
  )
}
