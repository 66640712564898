import { recordedActivitiesApi } from '@local/src/services/recordedActivities/recordedActivities'
import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query/react'

export const store = configureStore({
  reducer: {
    [recordedActivitiesApi.reducerPath]: recordedActivitiesApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(recordedActivitiesApi.middleware),
})

setupListeners(store.dispatch)
