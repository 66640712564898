import { isNil, includes } from 'ramda'

export const parseYoutubeId = (url: string): string => {
  if (!isNil(url) && url.length > 0 && typeof url === 'string') {
    let extracted
    if (includes('v=', url)) {
      extracted = url
        .substring(url.lastIndexOf('v=') + 2, url.length)
        .split('&')[0]

      if (/[a-zA-Z0-9_-]{11}/.exec(extracted)) {
        return !isNil(extracted) ? extracted : ''
      }
    } else {
      extracted = url.split('/').join('&').split('&')
      const id = extracted.filter(
        (segment: string) => /[a-zA-Z0-9_-]{11}/.exec(segment) && segment
      )[0]

      return !isNil(id) ? id : ''
    }
  }
  return ''
}
