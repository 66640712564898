import React, { useCallback, useEffect, useState } from 'react'
import {
  useGetRecordedActivitiesCategoriesQuery,
  useGetRecordedActivitiesWithMetaDataQuery,
} from '@local/src/services/recordedActivities/recordedActivities'
import VideoGrid from '@local/src/Components/VideoGrid'
import {
  Dropdown,
  EmptyResult,
  ISelectOption,
  Pagination,
} from '@trr/common-component-library'
import { useHistory } from 'react-router-dom'

import s from './VideoList.module.scss'

export const VideoList: React.FunctionComponent = () => {
  const history = useHistory()

  enum queryParameters {
    page = 'page',
    subjectCategory = 'subjectCategory',
  }

  const pageFromUrl = new URLSearchParams(history.location.search).get(
    queryParameters.page
  )
    ? parseInt(
        new URLSearchParams(history.location.search).get(queryParameters.page)
      )
    : 1

  const subjectCategoryFromUrl =
    new URLSearchParams(history.location.search).get(
      queryParameters.subjectCategory
    ) ?? ''

  const [currentPage, setCurrentPage] = useState<number>(pageFromUrl)

  const [currentPickedFilter, setCurrentPickedFilter] = useState<ISelectOption>(
    { label: subjectCategoryFromUrl, value: subjectCategoryFromUrl }
  )

  useEffect(() => {
    history.push({
      search: `${queryParameters.page}=${currentPage}&${queryParameters.subjectCategory}=${currentPickedFilter.label}`,
    })
  }, [
    currentPage,
    currentPickedFilter,
    history,
    queryParameters.page,
    queryParameters.subjectCategory,
  ])

  const {
    data: videoData,
    isLoading: videoIsLoading,
    error: videoError,
  } = useGetRecordedActivitiesWithMetaDataQuery({
    page: currentPage,
    subjectCategory: currentPickedFilter.label,
  })

  const { data: subjectCategoriesData, isLoading: subjectCategoriesIsLoading } =
    useGetRecordedActivitiesCategoriesQuery()

  const handleDropDownChange = useCallback(
    (option: ISelectOption) => {
      const pickedOption = option ?? { label: '', value: '' }
      setCurrentPickedFilter(pickedOption)
      setCurrentPage(1)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPage]
  )

  const handlePaginationChange = useCallback((page: number) => {
    setCurrentPage(page)
  }, [])

  return (
    <div>
      {!videoIsLoading && !subjectCategoriesIsLoading && (
        <>
          <div className={s.VideoListDropDown}>
            <Dropdown
              label="Intresseområden"
              onChange={handleDropDownChange}
              selectOptions={subjectCategoriesData}
              value={
                currentPickedFilter.label ? currentPickedFilter : undefined
              }
              clearable
              testSelector={'webinarListFilter'}
            />
          </div>
          {videoError ? (
            <EmptyResult
              className={s.videoError}
              heading={'Här är det tomt'}
              description={
                'För närvarande har vi inget innehåll som matchar det du söker efter. Vi ber dig därför att ändra din sökning.'
              }
            />
          ) : (
            <>
              <VideoGrid webinarVideos={videoData.webinars} />
              <Pagination
                onChange={handlePaginationChange}
                currentPage={videoData.pagination.currentPage}
                totalPages={videoData.pagination.totalPages}
              />
            </>
          )}
        </>
      )}
    </div>
  )
}
