import getConfig from '@local/Utils/configService'
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react/'
import { getOIDCUser } from '@trr/app-shell-communication'

const { API_URL } = getConfig()

const OIDCUser = getOIDCUser()
const id_token = OIDCUser?.id_token || ''

export const trrFetchBaseQuery = () =>
  fetchBaseQuery({
    baseUrl: `${API_URL}`,
    prepareHeaders: (headers) => {
      headers.set('authorization', `Bearer ${id_token}`)
      headers.set('Accept-Language', 'sv')
      return headers
    },
  })
