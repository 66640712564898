import React, { useCallback } from 'react'
import {
  Icons,
  Typography,
  TypographyGroup,
  Dialog,
} from '@trr/common-component-library'
import YouTube from '@u-wave/react-youtube'
import { date } from '@trr/frontend-datetime'

import s from './VideoModal.module.scss'

interface IVideoModal {
  toggled: boolean
  handleClose?: () => void
  videoId?: string
  broadcastDate?: string
  body?: string
  heading?: string
}

export const VideoModal: React.FunctionComponent<IVideoModal> = ({
  toggled,
  handleClose,
  videoId,
  broadcastDate,
  body,
  heading,
}) => {
  const handleTextFocus = useCallback(
    (event: React.SyntheticEvent<HTMLHeadingElement>) => {
      ;(event.target as HTMLHeadingElement).blur()
    },
    []
  )
  return (
    toggled && (
      <Dialog
        heading=""
        onClose={handleClose}
        className={s.RecordedActivitesModal}
        size="medium"
      >
        {videoId && toggled && (
          <YouTube
            video={videoId}
            width="100%"
            height="400px"
            className={s.VideoModal}
            allowFullscreen
            showRelatedVideos={false}
            showInfo={false}
            annotations={false}
            showCaptions={false}
          />
        )}
        <div className={s.Info}>
          {broadcastDate && (
            <Typography variant="body1" className={s.Date}>
              <>
                <Icons.CalendarFilledIcon size={16} color={s.ChevronColor} />
                {date.format(broadcastDate, { style: 'numeric' })}
              </>
            </Typography>
          )}
          <TypographyGroup gutterBottom={false}>
            <Typography
              variant="h2"
              component="h1"
              gutterBottom={!!body}
              tabIndex={0}
              onFocus={handleTextFocus}
            >
              {heading}
            </Typography>
            {body && <Typography variant="body1">{body}</Typography>}
          </TypographyGroup>
        </div>
      </Dialog>
    )
  )
}
