type MapToOutput<I, U = I> = (v: I) => U

export const lockScrollbar: MapToOutput<boolean, void> = (
  showModal: boolean
) => {
  const bg = document.getElementsByTagName('html')[0]
  return showModal
    ? (bg.style.overflowY = 'hidden')
    : (bg.style.overflowY = 'scroll')
}
