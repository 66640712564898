import React from 'react'
import { IAppShellConfiguration } from '@local/src/App.types'
import { Provider } from 'react-redux'
import { AppShellRouter, KeyRoute } from '@local/src/Router'
import { AppShellDataProvider } from '@local/src/AppShellData'
import { store } from '@local/src/Store/configureStore'
import { VideoList } from '@local/src/Components/VideoList'

const App: React.FunctionComponent<IAppShellConfiguration> = (appShellData) => (
  <Provider store={store}>
    <AppShellDataProvider value={appShellData}>
      <AppShellRouter currentKey={appShellData.currentKey}>
        <KeyRoute urlKey="recorded-activities-page">
          <VideoList />
        </KeyRoute>
      </AppShellRouter>
    </AppShellDataProvider>
  </Provider>
)

export default App
